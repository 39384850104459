import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.analytic',
        path: `${APP_PREFIX_PATH}/dashboards/analytic`,
        component: React.lazy(() => import('views/app-views/dashboards/analytic')),
    },
    {
        key: 'dashboard.sales',
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        component: React.lazy(() => import('views/app-views/dashboards/sales')),
    },
    {
        key: 'components.data-display',
        path: `${APP_PREFIX_PATH}/components/data-display`,
        component: React.lazy(() => import('views/app-views/components/data-display')),
    },
    {
        key: 'components.data-display.leads',
        path: `${APP_PREFIX_PATH}/components/data-display/leads`,
        component: React.lazy(() => import('views/app-views/components/data-display/leads')),
    },
    {
        key: 'components.data-display.msfh-grants',
        path: `${APP_PREFIX_PATH}/components/data-display/msfh-grants`,
        component: React.lazy(() => import('views/app-views/components/data-display/msfh-grants')),
    },
    {
        key: 'components.data-display.esw-intake',
        path: `${APP_PREFIX_PATH}/components/data-display/esw-intake`,
        component: React.lazy(() => import('views/app-views/components/data-display/esw-intake')),
    },
    {
        key: 'components.data-display.pgt-leads',
        path: `${APP_PREFIX_PATH}/components/data-display/pgt-leads`,
        component: React.lazy(() => import('views/app-views/components/data-display/pgt-leads')),
    },
    {
        key: 'components.data-display.solar',
        path: `${APP_PREFIX_PATH}/components/data-display/solar`,
        component: React.lazy(() => import('views/app-views/components/data-display/solar')),
    },
    {
        key: 'components.data-display.hubspot-contacts',
        path: `${APP_PREFIX_PATH}/components/data-display/hubspot-contacts`,
        component: React.lazy(() => import('views/app-views/components/data-display/hubspot-contacts')),
    },
    {
        key: 'components.data-display.facebook-leads',
        path: `${APP_PREFIX_PATH}/components/data-display/facebook-leads`,
        component: React.lazy(() => import('views/app-views/components/data-display/facebook-leads')),
    },
    {
        key: 'components.data-display.bing-leads',
        path: `${APP_PREFIX_PATH}/components/data-display/bing-leads`,
        component: React.lazy(() => import('views/app-views/components/data-display/bing-leads')),
    },
    {
        key: 'components.data-display.contact-details',
        path: `${APP_PREFIX_PATH}/components/data-display/contact-details`,
        component: React.lazy(() => import('views/app-views/components/data-display/contact-details')),
    },
    {
        key: 'components.data-entry-input-search',
        path: `${APP_PREFIX_PATH}/components/data-entry/input`,
        component: React.lazy(() => import('views/app-views/components/data-entry/input')),
    },
    {
        key: 'components.data-display.statistic',
        path: `${APP_PREFIX_PATH}/components/data-display/statistic`,
        component: React.lazy(() => import('views/app-views/components/data-display/statistic')),
    },
    {
        key: 'components.data-entry-add-data',
        path: `${APP_PREFIX_PATH}/components/data-display/timeline`,
        component: React.lazy(() => import('views/app-views/components/data-display/timeline')),
    },
    // {
    //     key: 'components.ai-reports',
    //     path: `${APP_PREFIX_PATH}/components/data-display/collapse`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/collapse')),
    // },
    // {
    //     key: 'components.data-display.comment',
    //     path: `${APP_PREFIX_PATH}/components/data-display/comment`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/comment')),
    // },
    // {
    //     key: 'components.data-display.descriptions',
    //     path: `${APP_PREFIX_PATH}/components/data-display/descriptions`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/descriptions')),
    // },
    // {
    //     key: 'components.data-display.empty',
    //     path: `${APP_PREFIX_PATH}/components/data-display/empty`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/empty')),
    // },
    // {
    //     key: 'components.data-display.list',
    //     path: `${APP_PREFIX_PATH}/components/data-display/list`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/list')),
    // },
    // {
    //     key: 'components.data-display.popover',
    //     path: `${APP_PREFIX_PATH}/components/data-display/popover`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/popover')),
    // },
    // {
    //     key: 'components.data-display.tabs',
    //     path: `${APP_PREFIX_PATH}/components/data-display/tabs`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/tabs')),
    // },
    // {
    //     key: 'components.data-display.timeline',
    //     path: `${APP_PREFIX_PATH}/components/data-display/timeline`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/timeline')),
    // },
    // {
    //     key: 'components.data-display.tooltip',
    //     path: `${APP_PREFIX_PATH}/components/data-display/tooltip`,
    //     component: React.lazy(() => import('views/app-views/components/data-display/tooltip')),
    // },
    {
        key: 'components.feedback',
        path: `${APP_PREFIX_PATH}/components/feedback`,
        component: React.lazy(() => import('views/app-views/components/feedback')),
    },
    {
        key: 'components.feedback.edited-leads',
        path: `${APP_PREFIX_PATH}/components/feedback/edited-leads`,
        component: React.lazy(() => import('views/app-views/components/feedback/edited-leads')),
    },
    {
        key: 'components.feedback.delete-list',
        path: `${APP_PREFIX_PATH}/components/feedback/delete-list`,
        component: React.lazy(() => import('views/app-views/components/feedback/delete-list')),
    },
    {
        key: 'components.feedback.hubspot-list',
        path: `${APP_PREFIX_PATH}/components/feedback/hubspot-list`,
        component: React.lazy(() => import('views/app-views/components/feedback/hubspot-list')),
    },
    {
        key: 'components.feedback.approved-zipcodes',
        path: `${APP_PREFIX_PATH}/components/feedback/zipcodes`,
        component: React.lazy(() => import('views/app-views/components/feedback/zipcodes')),
    },
    {
        key: 'apps',
        path: `${APP_PREFIX_PATH}/apps`,
        component: React.lazy(() => import('views/app-views/apps')),
    },
    {
        key: 'apps.mail',
        path: `${APP_PREFIX_PATH}/apps/mail/*`,
        component: React.lazy(() => import('views/app-views/apps/mail')),
    },
    {
        key: 'apps.chat',
        path: `${APP_PREFIX_PATH}/apps/chat/*`,
        component: React.lazy(() => import('views/app-views/apps/chat')),
    },
    {
        key: 'components.chart',
        path: `${APP_PREFIX_PATH}/components/charts`,
        component: React.lazy(() => import('views/app-views/components/charts')),
    },
    {
        key: 'components.chart.apex-charts',
        path: `${APP_PREFIX_PATH}/components/charts/apex-charts`,
        component: React.lazy(() => import('views/app-views/components/charts/apex')),
    },
    {
        key: 'components.maps',
        path: `${APP_PREFIX_PATH}/components/maps`,
        component: React.lazy(() => import('views/app-views/components/maps')),
    },
    {
        key: 'components.maps.simple-map',
        path: `${APP_PREFIX_PATH}/components/maps/simple-map`,
        component: React.lazy(() => import('views/app-views/components/maps/simple-map')),
    }
]