const FirebaseConfig = {
  apiKey: 'AIzaSyBELtYReVxBryq_MQ0II3_kAucy8wVTZwc',
  authDomain: 'bnt-leads-management.firebaseapp.com',
  databaseURL: 'https://bnt-leads-management-default-rtdb.firebaseio.com',
  projectId: 'bnt-leads-management',
  storageBucket: 'bnt-leads-management.appspot.com',
  messagingSenderId: '550032052770',
  appId: '1:550032052770:web:e6372a207026714a3e707b',
  measurementId: 'G-Q6G07TZHJG'
};

export default FirebaseConfig

